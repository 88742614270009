import React, { useEffect, useState,useCallback } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import { convertFromHTML, ContentState } from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'draftjs-to-html'
import './forms.css';
import './buttons.css';
import './main.css'
import { useNavigate } from 'react-router-dom'
import api from '../../config/configApi'
import Button from "../Button";
import draftToMarkdown from 'draftjs-to-markdown';
import {convert}  from 'html-to-text'
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import { upload } from '@testing-library/user-event/dist/upload';
import { useDropzone } from 'react-dropzone';


import styled from 'styled-components';


const ContainerDrop = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px;
	border-width: 2px;
	border-radius: 10px;
	border-style: dashed;
	background-color: #fafafa;
	color: black;
	font-weight: bold;
	font-size: 1.4rem;
	outline: none;
	transition: border 0.24s ease-in-out;
`;

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };
function Forms(props) {
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
      );
      const [convertedContent, setConvertedContent] = useState(null);

    const [editorSnopse, setEditorSnopse] = useState(() => EditorState.createEmpty());
    const [stateInputTitre, setStateInputTitre] = useState();
    const [stateInputCategorie, setStateInputCategorie] = useState();
    const [stateInputIngredients, setStateInputIngredients] = useState(() => EditorState.createEmpty());
    const [stateInputEtapes, setStateInputEtapes] = useState(() => EditorState.createEmpty());
    const [stateInputInfos, setStateInputInfos] = useState();
    const [isChecked, setIsChecked] = useState();
    const [image, setImage] = useState('');
    const [imageG, setImageG] = useState([]);

    const navigate = useNavigate()
    const goHome = () => { navigate("/") }

    const valorTitre = e => { setStateInputTitre(e); }
    const valorCategorie = e => { setStateInputCategorie(e); }
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);
    
    
    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
          })));
        acceptedFiles.map((file) => {
          const reader = new FileReader()
    
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {
          // Do whatever you want with the file contents
            const binaryStr = reader.result
            setImageG(binaryStr)
          }
          reader.readAsDataURL(file)
        })
        
      }, [])
      const {getRootProps, getInputProps} = useDropzone({onDrop})
     
    const addTodo = async e => {
        e.preventDefault();
        setStateInputTitre(()=>'');
        setStateInputCategorie(()=>'');
            setEditorSnopse(() => EditorState.createEmpty());
            setStateInputEtapes(() => EditorState.createEmpty());
        let formData = new FormData();

        for (var i = 0; i < files.length; i++) {
            let file = files[i];
            
            formData.append("file", file);
      
        const form ={
            image: imageG,
            titre: stateInputTitre,
            categorie:stateInputCategorie,
            resumo: draftToHtml(convertToRaw(editorSnopse.getCurrentContent())),
            texto: draftToHtml(convertToRaw(stateInputEtapes.getCurrentContent())),
            
        }
        console.log(form)
        
    
     
       const headers = {
            'headers': {
              'Content-Type': 'application/json'
            }
          }
        await api.post("/add_post.php",form)
        .then((response)=>{
            console.log(response.data)
           
            
        }).catch((err)=>{
            
        })
    }
    }
    const blocks = (convertToRaw(stateInputIngredients.getCurrentContent()).blocks)
    const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    useEffect(() => {
        
        let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
    
    }, [editorState]);

    function createMarkup(html) {
        return {
          __html: DOMPurify.sanitize(html)
        }
      }

     
 /* const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });*/
  const fileImage = files.map(file => <li key={file.path}>{file.path}</li>);
  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));
console.log(files.length)
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));

  }, []);
    return (
        <div className="animate-appear with-sidebar">

            <main >
                <form onSubmit={addTodo} className="form-cadastro">
                    <fieldset>
                        <legend>Adicionar Novo Post</legend>
                        <div className="map-container">
                            <div id="mapid">
                            </div>
                        </div>
                        <div className="input-block">
                            <label htmlFor="name"> Adicionar Titúlo</label>
                            <input id="name" name="pratos" value={stateInputTitre} onChange={e => valorTitre(e.target.value)} />
                        </div>
                        <div className="input-block">
                            <label htmlFor="name">Categorie</label>
                            <input id="name" name="pratos" value={stateInputCategorie} onChange={e => valorCategorie(e.target.value)} />
                        </div>                       
                        <div className="input-block" style={{ border: "1px solid black", padding: '2px', minHeight: '400px' }}>
                            <label htmlFor="about">Resumo </label>
                            <Editor
                                editorState={editorSnopse}
                                onEditorStateChange={setEditorSnopse}
                            />            
                            
                        </div>
                        <div className="input-block" style={{ border: "1px solid black", padding: '2px', minHeight: '400px' }}>
                            <label htmlFor="about">Texto </label>
                            <Editor
                                editorState={stateInputEtapes}
                                onEditorStateChange={setStateInputEtapes}
                            /> 
                        </div>
                         

                        <div className="input-block">
                        
              
              
                <div className="container">

                  <ContainerDrop   {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Solte arquivos aqui para enviar, ou Selecionar arquivos</p>
                  </ContainerDrop>
                  <div style={thumbsContainer}>
                    {thumbs}
                  </div>

                </div>
              </div>
                        

                    </fieldset>
                    <Button type="submit" className="primary-button" Text="Publicar"></Button>
                </form>
            </main>
            
        </div>
    )
}
export default Forms;