import React from 'react'
import { useLocation } from 'react-router-dom'
import DOMPurify from 'dompurify';
import "./Article.css";
import MenuNav from './../../composant/NavBarAdmin'

export default function Article() {
  const location = useLocation()
  const LIMIT = 3000;
  function createMarkup(html) {
    console.log(html.substr(0, LIMIT))
    return {
      __html: DOMPurify.sanitize(html.substr(0))

    }
  }
  return (
    <>
    <section id="content-home">
        <MenuNav />
      </section>
     
      <div className='images-body'>
        <h2>  {location.state.title}</h2>
        <p><img id="logo" src={"https://ngs.adv.br/" + location.state.imagem} alt="" /></p>


        <div
          className='body-post' key={location.state.id_post}
          dangerouslySetInnerHTML={createMarkup(location.state.body)}>

        </div>
      </div>
     
      

      
      
      <footer className="rodape">
        Todos os direitos reservados &copy; 2025
        
      </footer>
    </>





  )
}
