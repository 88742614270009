import {createStore,applyMiddleware, CombineReducers, combineReducers}from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import articleReducer from './articles/articleReducer'
import thunk from 'redux-thunk'
import { createStoreHook } from 'react-redux';




const store = configureStore({
    reducer: {
      articleReducer,
      
      devTools:true
    }
  })
  export default store