import React from 'react'
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import img3 from './../../assets/images/presicio3.jpg'


import './Blog.css'
import { useNavigate } from 'react-router-dom'

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'draftjs-to-html'
import draftToMarkdown from 'draftjs-to-markdown';
import { convert } from 'html-to-text'
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';



export default function Restaurantes() {
    const [readMore, setReadmore] = useState();
    const LIMIT = 300;
    const [dataRestaurantes, setDataRestaurantes] = useState([]);
    const [titre, setTitre] = useState();
    const [categorie, setCategorie] = useState()
    const carousel = useRef(null);
    const UrlBase = 'https://ngs.adv.br/';
    const getRestaurantes = async () => {
        fetch(UrlBase + 'blog.php')
            .then(response => response.json())
            .then(data => {

                setDataRestaurantes(data);
                setTitre(data.titre)
                setCategorie(data.categorie)
            })

    }


    useEffect(() => {
        getRestaurantes();

    }, []);

    const handleLeftClick = (e) => {
        e.preventDefault();
        console.log(carousel.current.offsetWidth);
        carousel.current.scrollLeft -= carousel.current.offsetWidth;
    }
    const handleRightClick = (e) => {
        e.preventDefault();
        carousel.current.scrollLeft += carousel.current.offsetWidth;
    }
    console.log(dataRestaurantes);
    const navigate = useNavigate()
    const goHome = () => { navigate("/") }
    const recetteUnicos = [];
    const categorieUnico = new Map();

    Object.values(dataRestaurantes).forEach((item) => {
        var duplicated = recetteUnicos.findIndex(redItem => {
            return item.titre_post == redItem.titre_post;
        }) > -1
        if (!duplicated) {
            recetteUnicos.push(item);
        }

    });
    dataRestaurantes.forEach((cat) => {
        if (!categorieUnico.has(cat.categorie)) {
            categorieUnico.set(cat.categorie, cat)
        }

    });
    const cat = [...categorieUnico.keys()]
    console.log(cat);

    function createMarkup(html) {
        console.log(html.substr(0, LIMIT))
        return {
          __html: DOMPurify.sanitize(html.substr(0, LIMIT))

          
         
        }}
    return (
        <div >

            <div className='container-blog'>

                {(Object.values(recetteUnicos).map(resto =>
                    <div className='carte-blog'>
                        <a href={'single_blog/' + resto.id_post}>
                           {resto.imagem_post && <img id="logo" src={"https://ngs.adv.br/" + resto.imagem_post} alt="" />}

                            <h2 key={resto.id_post}>{resto.titre_post} </h2>

                        </a>
                        <div
                  className='titre-post' key={resto.id_post}
                  dangerouslySetInnerHTML={createMarkup(resto.resumo_post)}>

                    
                </div>
                        
                    </div>


                ))}


            </div>
        </div>


    )
}
